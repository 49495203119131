<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="row">
                    <div class="col">
                      <input type="text" v-model="email" placeholder="Search.." name="search">
                      <button @click="searchCustomer()"><i class="fa fa-search"></i></button>
                      <ul>
                        <li v-for="customer in customersObject" :key="customer._id">{{ customer.email }} - <a href="javascript:void(0)" @click="removeCustomer(customer._id)"><i class="fa fa-trash text-danger"></i></a></li>
                      </ul>
                    </div>
                    <div class="col">
                        <select v-model="devices" id="devices" multiple>
                            <option v-for="device in allDevices" :key="device._id" :value="device._id">{{ device.serialNumber }} - {{ device.deviceName }}</option>
                        </select>
                        <small><b>Leave it blank to send notification to all devices</b></small>
                    </div>
                    <div class="col">
                        <md-field maxlength="3">
                            <label>Message</label>
                            <md-textarea v-model="message"></md-textarea>
                        </md-field>
                    </div>
                    <div class="col">
                        <md-button class="md-success" :disabled="isDisabled" @click="send()">Send<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " v-model="queriedData" 
                        class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                              </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Message" >{{ item.message }}</md-table-cell>
                                <md-table-cell md-label="Devices">
                                    <a href="javascript:void(0)" @click="$router.push({ path: 'admin-notification-detail', query: { id: item._id }})">{{ item.totalDevices }}</a>
                                </md-table-cell>
                                <md-table-cell md-label="Delivered" >{{ item.deliveredNotificationCount }}</md-table-cell>
                                <md-table-cell md-label="Undelivered" >{{ item.undeliveredNotificationCount }}</md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <md-card-actions md-alignment="space-between">
                            <div class="">
                                <p class="card-category">
                                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                                </p>
                            </div>
                            <pagination
                                class="pagination-no-border pagination-success"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="total"
                            >
                            </pagination>
                        </md-card-actions>
                        <h4 v-if="tableData.length == 0">No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import NotificationService from "../../../services/SuperAdmin/NotificationService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
        queriedData() {
          let result = this.tableData;
          if (this.searchedData.length > 0) {
              result = this.searchedData;
          }
          return result.slice(this.from, this.to);
        },
        to() {
          let highBound = this.from + this.pagination.perPage;
          if (this.total < highBound) {
              highBound = this.total;
          }
          return highBound;
        },
        from() {
          return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
          return this.searchedData.length > 0
              ? this.searchedData.length
              : this.tableData.length;
        },
  },
  data() {
    return {
      isDisabled: false,
      allDevices: [],
      devices: [],
      message: "",
      tableData: [],
      customers: [],
      customersObject: [],
      email: "",

      //Pagination
      pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
      },
      searchedData: [],
    };
  },
  methods: {
      getAllDevices() {
        this.isDisabled = true;
        let data = {
          customers: this.customers,
          fcmToken: true
        }
        NotificationService.allDeviceslist(data)
          .then((response) => {
            this.isDisabled = false;
            this.allDevices = response.data.data;
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      searchCustomer() {
        this.isDisabled = true;
        let data = {
          email: this.email,
          customers: this.customers
        }
        NotificationService.searchCustomer(data)
          .then((response) => {
            this.isDisabled = false;
            this.customers.push(response.data.data._id);
            this.customersObject.push(response.data.data);
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      removeCustomer(id) {
        this.customersObject = this.customersObject.filter((item) => item._id !== id);
        const index = this.customers.indexOf(id);
        this.customers.splice(index, 1);
      },
      getNotificationList() {
        this.isDisabled = true;
        NotificationService.getNotificationList()
          .then((response) => {
            this.isDisabled = false;
            this.tableData = response.data.data;
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      send() {
        this.isDisabled = true;
        let data = {
            devices: this.devices,
            message: this.message,
            customers: this.customers,
            fcmToken: true
        }
        NotificationService.send(data)
          .then((response) => {
            this.isDisabled = false;
            this.message = "";
             $('#devices').val("");
             this.getNotificationList();
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      }
  },
  watch: {
    customers() {
      this.getAllDevices()
    }
  },
  beforeMount() {
    this.getAllDevices();
    this.getNotificationList();
  },
  mounted() {
        $('#devices').select2({
            placeholder: "Select Devices",
            theme: "classic"
        }).on('select2:select', (e) => {
            this.devices.push(e.params.data.id);
        }).on('select2:unselect', (e) => {
            const index = this.devices.indexOf(e.params.data.id);
            this.devices.splice(index, 1);
        });
}
};
</script>
<style>
.select2-container{
  width: 100% !important;
}
</style>