import { render, staticRenderFns } from "./KeyMappingTab.vue?vue&type=template&id=56a2725e"
import script from "./KeyMappingTab.vue?vue&type=script&lang=js"
export * from "./KeyMappingTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports