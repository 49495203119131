import http from "../../http-common-super-admin";

class PriceDataService {
  syncPricing() {
    return http.get("price/sync");
  }
  listPrice() {
    return http.get("price/list");
  }
  priceDetail(data) {
    return http.get("price/detail", { params: data});
  }
  updatePrice(data) {
    return http.post("price/update", data);
  }
  getAppVersionDetail(data) {
    return http.get("price/get/app-version", { params: data});
  }
  updateAppVersion(data) {
    return http.post("price/update/app-version", data);
  }

  addCurrency(data) {
    return http.post("price/add/currency", data);
  }
  getCurrency() {
    return http.get("price/list/currency");
  }
  deleteCurrency(data) {
    return http.delete("price/delete/currency", { params: data });
  }
  updateCurrency(data) {
    return http.post("price/update/currency", data);
  }
  countryLookup(data) {
    return http.get("price/country/lookup", { params: data });
  }
  syncCurrencyRate(data) {
    return http.get("price/currency/sync", { params: data });
  }
}

export default new PriceDataService();